<template>
  <div class="m-5 p-3">
    <el-row>
      <el-col :md="15" class="pr-4">
        <div>
          <h2 class="pb-2">{{ $t("PrivateOnline.title") }}</h2>
          <div v-html="$t('PrivateOnline.desc')"></div>
        </div>
      </el-col>
      <el-col :md="9" class="img">
        <el-image
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/adobe.jpg"
        />
      </el-col>
    </el-row>
    <div class="pt-5">
      <h4 style="font-weight:bold;">{{ $t("PrivateOnline.how_works") }}</h4>
      <ul>
        <li>{{ $t("PrivateOnline.desc_works") }}</li>
      </ul>
      <el-image
        src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/tutoring_flow_chart_en.png"
        title="tutoring flow chart"
        >></el-image
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.img {
  padding-left: 20px;
}

@media (max-width: 992px) {
  .img {
    padding-top: 20px;
    padding-left: 20px;
  }
}
</style>
